<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px">
    <v-row no-gutters class="mx-2" v-if="study">
      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <!-- Type -->
          <v-chip>{{ study.type }}</v-chip>

          <!-- State -->
          <v-chip>{{ study.state }}</v-chip>
        </v-row>
      </v-col>

      <!-- Details -->
      <v-col cols="12">
        <v-row no-gutters v-if="study">
          <!-- FM Referent -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.fm_referent") }} :</span>
            <FMReferent :item="study.fm_referent" />
          </v-col>

          <!-- Reviewed by -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.reviewed_by") }} :</span>
            <FMReferent :item="study.reviewed_by" />
          </v-col>

          <!-- SKU -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.sku") }}</span>
            <span>{{ study.sku }}</span>
          </v-col>

          <!-- Weight -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.weight") }} :</span>
            <span>{{ study.weight }}g</span>
          </v-col>

          <!-- Produced quantity -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.produced_quantity") }} :</span>
            <span>{{ study.produced_quantity }}</span>
          </v-col>

          <!-- Colors -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.colors") }} :</span>
            <span v-if="Array.isArray(study.colors)">
              {{ study.colors.toString() }}
            </span>
            <span v-else>{{ $t("common.misc.NA") }}</span>
          </v-col>

          <!-- Brand collection -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.brand_collection") }} :</span>
            <BrandCollectionLink :brand-collection="study.brandCollection" />
          </v-col>

          <!-- Project impact -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.project_impact") }} :</span>
            <ProjectImpactLink :project-impact="study.projectImpact" />
          </v-col>

          <!-- Manufacturers -->
          <v-col cols="12">
            <span>
              {{ $tc("studies.labels.manufacturers", study.suppliers.length) }}
              :
            </span>
            <SupplierLink
              v-for="supplier of study.suppliers"
              :key="supplier.id"
              :supplier="supplier"
            />
          </v-col>

          <!-- Raw composition -->
          <v-col cols="12">
            <span>{{ $t("studies.labels.raw_composition") }} :</span>
            <span>{{ study.raw_composition }}</span>
          </v-col>

          <!-- Showcase settings -->
          <v-col cols="12">
            <v-divider class="my-2" />
            <div class="text-h4">
              {{ $t("studies.showcaseSettings.title") }}
            </div>

            <v-row no-gutters>
              <!-- View type -->
              <v-col cols="12">
                <span>
                  {{ $t("studies.labels.showcaseSettings.viewType") }}
                  :
                </span>
                <span>{{ study.showcaseSettings.viewType }}</span>
              </v-col>

              <!-- Has Product Details -->
              <v-col cols="12">
                <span>
                  <IconCheckOrTimes
                    :enabled="study.showcaseSettings.hasProductDetails"
                  />
                </span>
                <span>
                  {{ $t("studies.labels.showcaseSettings.hasProductDetails") }}
                </span>
              </v-col>

              <!-- Has Materials -->
              <v-col cols="12">
                <span>
                  <IconCheckOrTimes
                    :enabled="study.showcaseSettings.hasMaterials"
                  />
                </span>
                <span>
                  {{ $t("studies.labels.showcaseSettings.hasMaterials") }}
                </span>
              </v-col>

              <!-- hasTraceability -->
              <v-col cols="12">
                <span>
                  <IconCheckOrTimes
                    :enabled="study.showcaseSettings.hasTraceability"
                  />
                </span>
                <span>
                  {{ $t("studies.labels.showcaseSettings.hasTraceability") }}
                </span>
              </v-col>

              <!-- hasImpacts -->
              <v-col cols="12">
                <span>
                  <IconCheckOrTimes
                    :enabled="study.showcaseSettings.hasImpacts"
                  />
                </span>
                <span>
                  {{ $t("studies.labels.showcaseSettings.hasImpacts") }}
                </span>
              </v-col>

              <!-- hasRecyclability -->
              <v-col cols="12">
                <span>
                  <IconCheckOrTimes
                    :enabled="study.showcaseSettings.hasRecyclability"
                  />
                </span>
                <span>
                  {{ $t("studies.labels.showcaseSettings.hasRecyclability") }}
                </span>
              </v-col>

              <!-- Redirection type -->
              <v-col cols="12">
                <span>
                  {{ $t("studies.labels.showcaseSettings.redirectionType") }}
                  :
                </span>
                <span>
                  {{
                    showcaseRedirectionType
                      ? showcaseRedirectionType.text
                      : $t("common.misc.NA")
                  }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <!-- Picture -->
          <v-col cols="12">
            <v-divider class="my-2" />
            <div class="text-h4">
              {{ $t("studies.picture.title") }}

              <!-- Edit picture btn -->
              <BtnIconEdit
                v-if="study.picture"
                @click="toggleEditPictureDialog(true)"
              />
              <BtnIconAdd v-else @click="toggleEditPictureDialog(true)" />

              <!-- Delete picture btn -->
              <BtnIconDelete
                v-if="study.picture"
                @click="deleteStudyPicture(study.picture.id)"
              />
            </div>
            <v-img v-if="study.picture" :src="study.picture.URL" width="400" />
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <Skeleton :occurrence="1" type="card" />
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="mx-2" v-else>
      <p>{{ $t("studies.no-study") }}</p>
      <v-btn icon class="btn-primary" @click="setShowAddDialog(true)">
        <v-icon small>fas fa-plus-circle</v-icon>
      </v-btn>
    </v-row>

    <!-- Picture upload -->
    <ChangePictureDialog
      v-if="showDialog.editPicture"
      :value="showDialog.editPicture"
      :UPDATE_API="`/studies/${study.id}/picture`"
      @close="(needRefresh) => toggleEditPictureDialog(false, needRefresh)"
    />
  </v-card>
</template>

<script>
export default {
  name: "ProductSpecificationsTab",

  components: {
    IconCheckOrTimes: () => import("@/components/Common/IconCheckOrTimes"),
    BtnIconDelete: () => import("@/components/Common/Buttons/BtnIconDelete"),
    BtnIconEdit: () => import("@/components/Common/Buttons/BtnIconEdit"),
    BtnIconAdd: () => import("@/components/Common/Buttons/BtnIconAdd"),
    ChangePictureDialog: () =>
      import("@/components/Common/Picture/ChangePictureDialog"),
    SupplierLink: () => import("@/components/Common/Cards/SupplierLink"),
    ProjectImpactLink: () =>
      import("@/components/Common/Cards/ProjectImpactLink"),
    BrandCollectionLink: () =>
      import("@/components/Common/Cards/BrandCollectionLink"),
    FMReferent: () => import("@/components/Common/Cards/FMReferent"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
  },
  mixins: [],

  props: {
    product: { type: Object, default: () => null },
    study: { type: Object, default: () => null },
  },

  data() {
    return {
      showDialog: {
        add: false,
        edit: false,
        delete: false,
        editPicture: false,
      },

      isLoadingDelete: false,
    };
  },

  methods: {
    setShowAddDialog(val, needRefresh = false) {
      if (needRefresh) this.$emit("refresh");
      this.showDialog.add = val;
    },
    setShowEditDialog(val, needRefresh = false) {
      if (needRefresh) this.$emit("refresh");
      this.showDialog.edit = val;
    },
    setShowDeleteDialog(val, needRefresh = false) {
      if (needRefresh) this.$emit("refresh");
      this.showDialog.delete = val;
    },
    hideAddOrEditDialog(needRefresh = false) {
      if (needRefresh) this.$emit("refresh");
      this.showDialog.add = false;
      this.showDialog.edit = false;
    },

    toggleEditPictureDialog(val, needRefresh = false) {
      if (needRefresh) this.$emit("refresh");
      this.showDialog.editPicture = val;
    },

    deleteStudyPicture(pictureId) {
      this.$http
        .delete(`/studies/${this.study.id}/pictures/${pictureId}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingDelete = false;
        });
    },
  },

  watch: {
    study(newVal) {
      // console.log(newVal);
    },
  },

  computed: {
    accountId() {
      if (this.product.account && this.product.account.id)
        return this.product.account.id;
      return this.product.account;
    },
    supplierId() {
      if (this.study.supplier && this.study.supplier.id)
        return this.study.supplier.id;
      return this.study.supplier;
    },
    supplierRef() {
      if (this.study.supplier) return this.study.supplier.ref;
      return null;
    },
    supplierFormURL() {
      return `${process.env.VUE_APP_IMPACT_FORMS_URL}/suppliers/${this.supplierRef}`;
    },

    materialFormsURL() {
      return `${process.env.VUE_APP_IMPACT_FORMS_URL}/suppliers/${this.supplierRef}/materials`;
    },
    showcaseRedirectionType() {
      return this.$t("constants.showcaseRedirectionTypes.list").find(
        (el) => el.value === this.study.showcaseSettings.redirectionType
      );
    },
  },
};
</script>

<style scoped></style>
